<template>
  <div class="wrapper" style="overflow:auto" @scroll="handlescroll">
    <!-- header -->
    <div class="header">
      <div class="logo">
        <h1>
          <a href="http://www.trulegar.com/" target="_blank">
            <img style="width:97px;height:37px" src="@/assets/logo.png" alt="" />
          </a>
        </h1>
      </div>
      <div class="nav">
        <div class="item">涉外法律工具箱</div>
      </div>
      <div></div>
    </div>

    <div class="w">
      <div class="search">
        <div class="area-box">
          <div class="area">
            <div :class="['item', currentAreaIndex == i ? 'active' : '']" v-for="(item, i) in area" :key="i" @click="selectArea(i, item)">
              {{ item.name }}
            </div>
          </div>
          <div class="reset">
            <span></span>
            <span class="reset-btn" @click="reset">清空筛选条件</span>
          </div>
        </div>
        <div class="area-box city-box" v-if="city_list.length > 0">
          <div class="area">
            <div :class="['item', currentCityIndex == i ? 'active' : '']" v-for="(item, i) in city_list" :key="i" @click="selectCity(i, item)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="intro">
        <span>{{ intro }}</span>
      </div>
      <div class="list animate__animated animate__fadeIn  animate__faster" v-if="list.length > 0">
        <div class="item" v-for="(item, index) in list" :key="index">
          <template>
            <div class="title">{{ item.name }}</div>
            <div class="tool-box">
              <div class="tool" v-for="(tool, i) in item.guide_website" :key="i" @click="goWebSite(tool)">
                <img class="logo" :src="tool.logo" alt="" />
                <div class="detail">
                  <div class="name">{{ tool.name }}</div>
                  <div class="dsc">{{ tool.intro }}</div>
                </div>
              </div>
              <div class="empty" v-if="item.guide_website.length == 0">暂无数据</div>
            </div>
          </template>
        </div>
        <p class="tip" v-if="loading">加载中...</p>
        <p class="tip" v-if="noMore && this.list.length > 3">没有更多了</p>
      </div>
    </div>
    <!-- 关注我们 -->
    <div class="follow">
      <div class="title animate__animated animate__flipInX animate__faster">关注我们</div>
      <div class="qrcode animate__animated animate__flipInX animate__faster">
        <img src="@/assets/qrcode.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getListAPI, getAreaListAPI, getCountAPI, getintroAPI } from './api'
import _ from 'lodash'
export default {
  name: 'Index',

  data() {
    return {
      page: 1,
      page_size: 10,
      list: [],
      currentAreaIndex: 0,
      currentCityIndex: -1,
      area_list: [],
      searchData: {
        city_id: ''
      },
      city_list: {},
      loading: false,
      last_page: 1,
      intro: ''
    }
  },

  mounted() {
    this.getList()
    this.getAreaList()
    this.getintro()
  },
  computed: {
    area() {
      let arr = []
      const { area_list } = this
      area_list.forEach(item => {
        arr.push(item)
        if (item.children && item.children.length > 0) {
          arr.push(...item.children)
        }
      })
      // if (area_list[0].children) {
      //   arr = [area_list[0], ...area_list[0].children]
      // }
      return arr
    },
    noMore() {
      return this.page >= this.last_page && !this.loading
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  watch: {
    currentAreaIndex: {
      handler(newV, oldV) {
        if (newV) {
          this.currentCityIndex = -1
        }
      }
    }
  },

  methods: {
    async getintro() {
      const res = await getintroAPI('website_intro')
      this.intro = res.values.intro
    },
    async getList(cb) {
      const { city_id } = this.searchData
      const params = { page: this.page, page_size: this.page_size, city_id }
      const res = await getListAPI(params)
      this.list = [...this.list, ...res.data]
      this.last_page = res.last_page
      cb && cb()
    },
    async goWebSite({ url, id }) {
      await getCountAPI(id)
      window.open(url, '_blank')
    },
    // 点击大洲
    selectArea(i, item) {
      this.list = []
      this.page = 1
      this.last_page = 1
      this.currentAreaIndex = i
      this.city_list = []
      if (item.pid !== 0) {
        this.findCity(this.area_list, item.id)
      }
      this.searchData.city_id = item.id
      this.getList(() => {
        this.loading = false
      })
    },
    // 点击国家
    selectCity(i, item) {
      this.list = []
      this.page = 1
      this.last_page = 1
      this.currentCityIndex = i
      this.searchData.city_id = item.id
      this.getList()
    },
    // 清空
    reset() {
      this.currentAreaIndex = 0
      this.currentCityIndex = -1
      this.city_list = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    async getAreaList() {
      const res = await getAreaListAPI()
      this.area_list = res || []
    },
    findCity(data, id) {
      data.map(item => {
        if (item.id == id) {
          this.city_list = item.children // 结果赋值
        } else {
          if (item.children && item.children.length > 0) {
            this.findCity(item.children, id)
          }
        }
      })
    },

    handlescroll: _.throttle(function(e) {
      let scrollTop = e.target.scrollTop //滑入屏幕滚动条滚动时，距离顶部的距离
      let windowHeitht = e.target.clientHeight //能看到的页面的高度
      let scrollHeight = e.target.scrollHeight //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht + 200
      if (total >= scrollHeight) {
        if (this.loading || this.noMore) return
        console.log(this.page)
        this.loading = true
        this.page += 1
        this.getList(() => {
          this.loading = false
        })
      } else {
        // console.log('还未到底')
      }
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f0f6ff;
  height: 100vh;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;

    .logo {
      width: 97px;
      height: 37px;
      a {
        display: block;
      }
    }
    .nav {
      display: flex;
      justify-content: center;
      .item {
        cursor: pointer;
        color: #394e6a;
        font-size: 20px;
        padding: 10px 20px;
        // font-weight: 600;
        // font-family: '宋体';
        &:hover {
          background-color: #f5f5f5;
          border-radius: 8px;
        }
      }
    }
  }
  .w {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 70px;
    padding-left: 10px;
    padding-right: 10px;
    .list {
      .item {
        margin-bottom: 50px;

        .title {
          color: #394e6a;
          font-size: 18px;
          margin-bottom: 20px;
        }
        .tool-box {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          justify-content: center;
          .tool {
            cursor: pointer;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 15px 10px;
            border-radius: 15px;
            &:hover {
              box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
            }
            .logo {
              display: block;
              width: 50px;
              height: 50px;
              border-radius: 10px;
            }
            .detail {
              margin-left: 10px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .name {
                font-size: 16px;
              }
              .dsc {
                color: rgb(156, 163, 175);
                font-size: 12px;
              }
            }
          }
          .empty {
            font-size: 14px;
            color: #394e6a;
          }
        }
      }
      .tip {
        text-align: center;
        margin: 10px 0;
        color: #394e6a;
        margin-bottom: 20px;
      }
    }
    .search {
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 10px;
      padding: 10px;

      .area-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-bottom: 10px;
        .area {
          display: flex;
          align-items: center;
          // display: grid;
          // grid-template-columns: repeat(auto-fill, 100px);
          // gap: 10px;
          // justify-content: center;
          color: #394e6a;
          // padding: 0 5px;
          flex-wrap: wrap;
          .item {
            cursor: pointer;
            text-align: center;
            padding: 5px 10px;
            font-size: 14px;
            white-space: nowrap;
          }
          .active {
            background-color: #f5f5f5;
            border-radius: 4px;
            color: #5dd5c8;
          }
        }
        .reset {
          padding: 5px 20px;
          display: flex;
          justify-content: space-between;
          .reset-btn {
            color: #999;
            cursor: pointer;
            font-size: 14px;
            &:hover {
              color: #00a6a7;
            }
          }
        }
      }
      .city-box {
        background-color: #f6f6f8;
        border-radius: 6px;
        padding: 8px 0;
        margin-top: 10px;
      }
    }
    .intro {
      margin-bottom: 30px;
      font-size: 14px;
      color: #999;
    }
  }
  .follow {
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &:hover {
      .title {
        display: none;
      }
      .qrcode {
        display: block;
      }
    }
    .title {
      writing-mode: vertical-rl;
      margin-right: 2px;
      border-radius: 2px;
      background-color: rgb(242, 245, 246);
      box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px;
      color: rgb(25, 137, 250);
      padding: 10px;
    }
    .qrcode {
      display: none;
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
