import { http } from '@/utils/request.js'
export function getListAPI(params) {
  return http({
    url: '/client/guideWebsite/misc/list',
    method: 'get',
    params,
    headers: {
      'Cache-control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: 0
    }
  })
}

export function getAreaListAPI() {
  return http({
    url: '/client/guideWebsite/misc/getCityTree',
    method: 'get'
  })
}
// 统计次数
export function getCountAPI(id) {
  return http({
    url: '/client/guideWebsite/misc/look',
    method: 'get',
    params: { id }
  })
}
// intro
export function getintroAPI(key) {
  return http({
    url: '/client/basic/setting/misc/detail',
    method: 'get',
    params: { key }
  })
}
